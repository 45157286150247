<footer>
    <img src="/assets/footer-img.png" class="top-image">
    <div class="container">
        <div class="row">
            <div class="col-md size-20">
            </div>
            <diV class="mobile img-mobile">
                <img src="/assets/footer-mobile.PNG">
            </diV>
            <div class="col web">
                <img src="/assets/group-6.png">
            </div>
            <div class="col web">
                <img src="/assets/image-174.png">
            </div>
            <div class="col web">
                <div class="row">
                    <div class="col-2">
                        <img src="/assets/tick-footer.PNG">
                    </div>
                    <div class="col  size-16">
                        Visualice y analice de forma instantánea el rendimiento del cultivo en tu finca
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <img src="/assets/tick-footer-green.png">
                    </div>
                    <div class="col  size-16">
                        Mantenga el potencial productivo de sus parcelas
                    </div>
                </div>
            </div>
            <div class="col web">
                <div class="row">

                </div>

            </div>
        </div>
        <div class="row links">
            <div class="col-md-2">

            </div>
            <div class="col-md">
                <a href="https://impulsobayer.es/pdf/es_privacidad.pdf">Política de Privacidad</a>
            </div>
            <div class="col-md">
                <a target="_blank" href="/assets/pdf/terminos_y_condiciones_promo_2021.pdf">Bases legales de la campaña</a>
            </div>
            <div class="col-md">
                <a href="https://impulsobayer.es/pdf/es_privacidad.pdf">Política de Cookies</a>
            </div>
            <div class="col-md-2">

            </div>
        </div>
        <div class="row text-center row-social">
            <div class="col">
                <a href="#" class="col-1 social-link">
                    <img src="/assets/social/youtube.png">
                </a>
                <a href="#" class="col-1 social-link">
                    <img src="/assets/social/twitter.png">
                </a>
                <a href="#" class="col-1 social-link">
                    <img src="/assets/social/facebook.png">
                </a>
            </div>
        </div>
        <div class="row text-center bottom-link">
            <a href="www.cropscience.bayer.es">
                www.cropscience.bayer.es
            </a>
        </div>
    </div>
</footer>