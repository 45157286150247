<div class="container">
    <div class="row">
        <div class="col-md-7">
            <h1>
                <b>La mejor solución de otoño para cereal</b>
            </h1>
            <div class="mobile mobile-image">
                <img src="/assets/group-2-mobile.jpg">
            </div>
            <h2>
                Descubre la mejor solución herbicida para tu cultivo de cereal, completa el formulario y llévate un descuento de 15€/pack
            </h2>
            <h2>
                Si además tratas en resiembra 2021 esas mismas hectáreas con RoundUp Ultimare, ¡llévate 3€/pack extras!
            </h2>
            <h3>
                <b>Para beneficiarte de la promoción completa el siguiente proceso:</b>
            </h3>
            <div class="form">
                <router-outlet>
                </router-outlet>
            </div>
        </div>
        <div class="col-md side-img web">
            <img src="/assets/group-2.jpg">
        </div>
    </div>

</div>



<app-footer></app-footer>