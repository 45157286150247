<div class="container">
    <div class="row form-steps">
        <div class="col-md-3">
            1. Identifícate
        </div>
        <div class="col-md-6 form-step-selected">
            2. ¿Cuántas hectáreas tienes?
        </div>
        <div class="col-md-3">
            3. Confirma tu reserva
        </div>
    </div>
    <hr>
    <div class="row row-2">
        <div class="col-7">
            <div class="form-group">
                <input type="text" class="form-control" value="Trigo" disabled>
            </div>
        </div>
        <div class="col">
            <div class="input-group mb-3">
                <input type="text" class="form-control" [formControl]="ha1">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Ha</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-7">
            <div class="form-group">
                <input type="text" class="form-control" value="Cebada" disabled>
            </div>
        </div>
        <div class="col">
            <div class="input-group mb-3">
                <input type="text" class="form-control" [formControl]="ha2">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Ha</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-7">
            <div class="form-group">
                <input type="text" class="form-control" value="Otros Cereales" disabled>
            </div>
        </div>
        <div class="col">
            <div class="input-group mb-3">
                <input type="text" class="form-control" [formControl]="ha3">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2">Ha</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-2">
        <div class="col-md-12 warning">
            {{warning}}
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <!-- <label for="id_bayer">Email address</label> -->
                <button class="form-control btn-1" (click)="next()">Siguiente paso</button>
            </div>
        </div>
    </div>
</div>